import { render, staticRenderFns } from "./ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi.vue?vue&type=template&id=acbe8bbc&scoped=true"
import script from "./ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi.vue?vue&type=script&lang=js"
export * from "./ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi.vue?vue&type=script&lang=js"
import style0 from "./ecoach_collapse_hinh_thuc_hoan_thanh_hoc_phi.vue?vue&type=style&index=0&id=acbe8bbc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbe8bbc",
  null
  
)

export default component.exports