import { render, staticRenderFns } from "./tableScoreWeekMeeting.vue?vue&type=template&id=6060f145"
import script from "./tableScoreWeekMeeting.vue?vue&type=script&lang=js"
export * from "./tableScoreWeekMeeting.vue?vue&type=script&lang=js"
import style0 from "./tableScoreWeekMeeting.vue?vue&type=style&index=0&id=6060f145&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports