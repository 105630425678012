<script>

import {formatDate} from "../../../core/filters";
import {GET_DETAIL_BOND_VALUE} from "../../../core/services/store/service.module";
import {NAME_STATUS_BOND} from "../../../core/config/payslipConfig";

export default {
  name: "trai-phieu-detail-item",
  data() {
    return {
      dialogTableVisible: false,
      dataDetails: [],
      dataBonds: [],
      dataBondYear: [],
      loadingDetail: false,
      options: [
        {
          label: 'Mốc tích lũy 1',
          value: 1
        },
        {
          label: 'Mốc tích lũy 2',
          value: 2
        },
        {
          label: 'Mốc tích lũy 3',
          value: 3
        },
        {
          label: 'Mốc tích lũy 4',
          value: 4
        },
        {
          label: 'Mốc tích lũy 5',
          value: 5
        }
      ],
      phase: 0,
      NAME_STATUS_BOND: NAME_STATUS_BOND
    }
  },
  watch: {
    phase: function (newVal, oldVal) {
      if (newVal) {
        this.dataDetails = []
        this.filterBond()
      }
    }
  },
  methods: {
    formatDate,
    formatMoney(value){
      value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
      return value
    },
    async openModal() {
      this.dialogTableVisible = true
      this.loadingDetail = true;
      await this.callApi()
    },
    async callApi() {
      await this.$store.dispatch(GET_DETAIL_BOND_VALUE).then((data) => {
        this.dataBonds = data?.data?.map(item => {
          this.phase = item?.milestone
          return item
        })
        this.filterBond()
      }).finally(() => {
        this.loadingDetail = false;
      })
    },
    filterBond() {
      if (this.dataBonds.length > 0) {
        const newData = this.dataBonds.filter(item => item?.milestone === this.phase);
        if (newData.length > 0) {
          newData?.map(e => {
            this.getDetails(e)
          })
        } else {
          this.getDetails()
        }
        this.dataBondYear = [1, 2, 3, 4, 5, 6, 7].map(item => {
          return {
            label: `Năm ${item}`,
            value: newData[0]?.items[item-1]?.amount ? this.formatMoney(newData[0]?.items[item-1]?.amount) : 'Chưa có dữ liệu'
          }
        })
      }
    },
    getDetails(data) {
      this.dataDetails = ['start_date', 'end_date', 'allowed_sale_date', 'amount_total', 'amount_sold', 'amount_balance'].map(item => {
        return {
          label: this.convertKeyToString(item),
          value: data ? this.formatData(data, item) : 'Chưa có dữ liệu',
        }
      })
    },
    formatData(data, key) {
      switch (key) {
        case 'start_date':
        case 'end_date':
        case 'allowed_sale_date':
          return this.formatDate(data[key])
        case 'amount_total':
        case 'amount_sold':
        case 'amount_balance':
          return this.formatMoney(data[key])
        default:
          return 'Chưa có dữ liệu'
      }
    },
    convertKeyToString(key) {
      if (key) {
        switch (key) {
          case "start_date":
            return 'Thời điểm bắt đầu tích lũy'
          case "end_date":
            return 'Thời điểm trái phiếu có hiệu lực'
          case "allowed_sale_date":
            return 'Thời điểm được chuyển nhượng/bán'
          case "amount_total":
            return 'Tổng giá trị trái phiếu'
          case "amount_sold":
            return 'Số lượng TP đã bán/chuyển nhượng'
          case "amount_balance":
            return 'Số lượng TP còn lại'
        }
      }
    },
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
}
</script>

<template>
  <div>
    <el-button type="text" @click="openModal">Xem chi tiết trái phiếu</el-button>
    <el-dialog title="Xem chi tiết trái phiếu" v-if="dialogTableVisible" :show-close="false" :visible.sync="dialogTableVisible"
               :width="isMobile()?'100vw': '60vw'">
      <div v-if="loadingDetail" class="text-center">
        <i class="el-icon-loading"></i>
      </div>
      <div style="overflow-y: auto; max-height: 500px;overflow-x: hidden; margin-top: -20px" v-else>
        <el-select v-model="phase" placeholder="Select">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; margin-top: 20px">
          <div>
            <h4 style="margin-bottom: 10px; font-size: 14px">Thông tin chi tiết:</h4>
            <el-table
                :data="dataDetails"
                border
                style="width: fit-content">
              <el-table-column
                  prop="label"
                  label="Giai đoạn"
                  width="260">
              </el-table-column>
              <el-table-column
                  prop="value"
                  label="Được chuyển nhượng/Bán"
                  width="220">
              </el-table-column>
            </el-table>
          </div>
          <div>
            <h4 style="margin-bottom: 10px; font-size: 14px">Giá trị trái phiếu tích lũy từng năm:</h4>
            <el-table
                :data="dataBondYear"
                border :show-header="false"
                style="width: fit-content">
              <el-table-column
                  prop="label"
                  width="260">
              </el-table-column>
              <el-table-column
                  prop="value"
                  width="220">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>