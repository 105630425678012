import { render, staticRenderFns } from "./ieltsmentor_collapse_chinh_sach_hoc_tap.vue?vue&type=template&id=16058e2e&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./ieltsmentor_collapse_chinh_sach_hoc_tap.vue?vue&type=script&lang=js"
export * from "./ieltsmentor_collapse_chinh_sach_hoc_tap.vue?vue&type=script&lang=js"
import style0 from "./ieltsmentor_collapse_chinh_sach_hoc_tap.vue?vue&type=style&index=0&id=16058e2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16058e2e",
  null
  
)

export default component.exports