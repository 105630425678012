import { render, staticRenderFns } from "./Teacher.vue?vue&type=template&id=1756749c&scoped=true"
import script from "./Teacher.vue?vue&type=script&lang=js"
export * from "./Teacher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1756749c",
  null
  
)

export default component.exports