import { render, staticRenderFns } from "./ecoach_collapse_dieu_khoan_dang_ky_hoc.vue?vue&type=template&id=7e27e5e0&scoped=true"
import script from "./ecoach_collapse_dieu_khoan_dang_ky_hoc.vue?vue&type=script&lang=js"
export * from "./ecoach_collapse_dieu_khoan_dang_ky_hoc.vue?vue&type=script&lang=js"
import style0 from "./ecoach_collapse_dieu_khoan_dang_ky_hoc.vue?vue&type=style&index=0&id=7e27e5e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e27e5e0",
  null
  
)

export default component.exports