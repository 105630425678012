import { render, staticRenderFns } from "./RegisterStudyForm.vue?vue&type=template&id=1bca7aaf&scoped=true"
import script from "./RegisterStudyForm.vue?vue&type=script&lang=js"
export * from "./RegisterStudyForm.vue?vue&type=script&lang=js"
import style0 from "./RegisterStudyForm.vue?vue&type=style&index=0&id=1bca7aaf&prod&scoped=true&lang=css"
import style1 from "./RegisterStudyForm.vue?vue&type=style&index=1&id=1bca7aaf&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bca7aaf",
  null
  
)

export default component.exports